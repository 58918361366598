<template>
  <ui-component-modal
    :modalTitle="'Edit configurations'"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveSpace"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooter="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table
        v-if="!isSavingSuccess && !isSavingError"
        class="table is-fullwidth is-striped is-narrow"
      >
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Layout</th>
            <th>Min people</th>
            <th>Max people</th>
          </tr>
        </thead>
        <tbody
          name="fade"
          is="transition-group"
          mode="out-in"
          :duration="{ enter: 300, leave: 300 }"
        >
          <tr v-if="mSpace.Settings.length === 0" key="NoConfig">
            <td colspan="4">
              <article class="message is-info">
                <div class="message-body">
                  No configartions found. Add configurations by clicking on the
                  + sign below
                </div>
              </article>
            </td>
          </tr>
          <tr v-for="(setting, index) in mSpace.Settings" :key="index">
            <td>
              <a @click="deleteSettingRow(index)" class="button is-small">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </span>
              </a>
            </td>
            <td>
              <span v-if="setting.SettingId > 0">{{
                setting.SettingId | getSettingName
              }}</span>
              <span v-if="setting.SettingId === 0" class="select">
                <select v-model="setting.SettingId">
                  <option
                    v-for="settingId in filteredSettingIds"
                    :key="settingId"
                    :value="settingId"
                  >
                    {{ settingId | getSettingName }}
                  </option>
                </select>
              </span>
            </td>
            <td>
              <input type="text" class="input" v-model="setting.MinSeats" />
            </td>

            <td>
              <input type="text" class="input" v-model="setting.MaxSeats" />
            </td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': setting.IsPublic }">
                    <a @click="setting.IsPublic = true">
                      <span>{{ $t('Form.Control.Yes') }}</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !setting.IsPublic }">
                    <a @click="setting.IsPublic = false">
                      <span>{{ $t('Form.Control.No') }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <a @click="addSettingRow" class="button is-small is-success">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations } from 'vuex'
import spaceProvider from '@/providers/space'

export default {
  props: {
    space: {
      default: null,
      type: Object,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mSpace: JSON.parse(JSON.stringify(this.space)),
      settingIds: [
        1, 2, 3,
        //4,
        5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20, 21, 22, 23, 24,
      ],
    }
  },

  created() {},

  computed: {
    filteredSettingIds() {
      let self = this
      let settingIds = []

      if (self.mSpace.Settings.length > 0) {
        for (let i = 0; i < self.settingIds.length; i++) {
          let settingIndex = self.mSpace.Settings.findIndex(
            (s) => s.SettingId === self.settingIds[i]
          )
          if (settingIndex === -1) {
            settingIds.push(self.settingIds[i])
          }
        }
      }

      return settingIds
    },
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    addSettingRow() {
      let self = this
      self.mSpace.Settings.push({
        SettingId: 0,
        MinSeats: 1,
        MaxSeats: 1,
      })
    },

    deleteSettingRow(index) {
      let self = this

      self.mSpace.Settings.splice(index, 1)
    },

    saveSpace() {
      let self = this
      self.isSaving = true
      self.isSavingSuccess = false
      self.isSavingError = false

      spaceProvider.methods
        .updateSpace(self.mSpace)
        .then((response) => {
          if (response.status === 200) {
            self.mSpace = response.data
            self.setSpace(self.mSpace)

            setTimeout(() => {
              self.$emit('spaceEdited', self.mSpace)
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error.response)
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
          self.isSavingSuccess = true
        })
    },
  },
}
</script>
